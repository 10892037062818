import type { ReactElement } from 'react';
import Script from 'next/script';

export function GTMDataLayer(): ReactElement {
  return (
    <Script
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `window.dataLayer = window.dataLayer || [];`,
      }}
    />
  );
}
