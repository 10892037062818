type MySectionOverviewConfiguration = {
  overviewPageUrl: string;
  storeMySectionSelectionInCookie: boolean;
  identifierParameter: string;
  mySectionType: string;
  identifierLabel: string;
};

export const getMySectionOverviewConfiguration = (sectionParams: Record<string, string>): MySectionOverviewConfiguration => {
  return {
    overviewPageUrl: sectionParams['mysection.overviewpage'],
    storeMySectionSelectionInCookie: sectionParams['mysection.storeMySectionSelectionInCookie'] === 'true',
    identifierParameter: sectionParams['mysection.identifierParameter'],
    mySectionType: sectionParams['mysection.type'],
    identifierLabel: sectionParams['mysection.identifierLabel'],
  };
};
