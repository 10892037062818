/* eslint-disable camelcase */
export const variantsWithSizes = {
  sixteen_nine: [1920, 1200, 1024, 960, 768, 720, 480, 384, 256, 128, 64],
  one_one: [1920, 1200, 1024, 960, 768, 720, 480, 384, 256, 128, 64],
  four_five: [922, 576, 288, 144],
  four_three: [1440, 720, 360, 180, 90],
  three_two: [1620, 810, 405, 203, 102],
  twentyone_nine: [1620, 810, 405, 203, 102],
  width: [1440, 720, 360, 180, 90],
};

export type Variant = keyof typeof variantsWithSizes;
export const variantPattern = new RegExp(`/base_(${Object.keys(variantsWithSizes).join('|')})/`, 'i');
