import { IconButton } from '@mediahuis/chameleon-react';
import { Close } from '@mediahuis/chameleon-theme-wl/icons';

import { useModal } from '@hubcms/data-access-modal';

import styles from './styles.module.scss';

const Modal = () => {
  const { isOpen, close, content } = useModal();

  if (!isOpen) return null;

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.buttonWrapper}>
        <IconButton
          icon={Close}
          onClick={() => {
            close();
          }}
          circular={true}
          className={styles.closeButton}
        />
      </div>
      <div className={styles.modalContent}>{content}</div>
    </div>
  );
};

export default Modal;
