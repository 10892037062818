import type { TeaserPropsOptions } from '@hubcms/domain-teaser';
import type { SectionParams } from '@hubcms/domain-cook';
import { getTeaserLabelsVisibility } from './getTeaserLabelsVisibility';

type GetTeaserPropsOptions = {
  todaysDate: string;
  sectionParams?: SectionParams;
};

export function getTeaserPropsOptions({ sectionParams = {}, todaysDate }: GetTeaserPropsOptions): TeaserPropsOptions {
  return {
    todaysDate,
    ...getTeaserLabelsVisibility(sectionParams),
  };
}
