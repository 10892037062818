import type { SectionParams } from '@hubcms/domain-cook';

import { getMySectionOverviewConfiguration } from './getMySectionOverviewConfiguration';

export function getSectionRegioIdentifier(sectionParams: SectionParams): string | null {
  const { mySectionType, identifierParameter } = getMySectionOverviewConfiguration(sectionParams);

  if (mySectionType === 'city') {
    return sectionParams[identifierParameter] ?? null;
  }

  return null;
}
