import dynamic from 'next/dynamic';

import { type CookData, isAuthorProfilePage } from '@hubcms/domain-cook';
import { getTeaserPropsOptions } from '@hubcms/utils-teaser';
import { useMemo } from 'react';

const Section = dynamic(() => import('./section'));
const Article = dynamic(() => import('./article'));

export function ResolutionComponent({ cookData, isBundle = false }: { cookData: CookData; isBundle?: boolean }) {
  // Object is used in useEffect hook further down the tree
  const teaserPropsOptions = useMemo(
    () =>
      getTeaserPropsOptions({
        todaysDate: cookData.todaysDate,
        sectionParams: cookData.sectionParams,
      }),
    [cookData.todaysDate, cookData.sectionParams],
  );

  if (cookData.resolution.context === 'sec' || isAuthorProfilePage(cookData)) {
    return <Section data={cookData} teaserPropsOptions={teaserPropsOptions} />;
  }
  if (cookData.resolution.context === 'art') {
    return <Article isBundle={isBundle} data={cookData} teaserPropsOptions={teaserPropsOptions} />;
  }
  throw Error('No component found to render as no supported type (or none at all) was returned from cook');
}
