import cdnConfig from '@mediahuis/chameleon-theme-wl/config/cdn.json';
import packageJson from '@mediahuis/chameleon-theme-wl/package.json';

type AssetExtension = 'png' | 'svg';

// baseURL contains trailing slash
// paths.logos contains no leading slash, but does contain trailing slash
export function createFocusCDNUrlForLogo(assetName: string, assetExtension: AssetExtension, brand = cdnConfig.brand) {
  return `${cdnConfig.baseURL}${packageJson.version}/${brand}/${cdnConfig.paths.logos}${assetName}.${assetExtension}`;
}
